.gpt3__navbar{
    display:flex;
    justify-content: space-between;
    align-items: center;
    
    
}
.gpt3__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
}



.gpt3__navbar-links_logo img {
    
    width: 57px;
    height: 47px;
    margin: 0;
    padding: 0;
    align-items: flex-start;
}
.gpt3__navbar-links_logo{
    margin-right: 4rem;
    margin-top: 10px;
    margin-bottom: -8px;
    padding-left: 2rem;
}

.gpt3__navbar-links_container {
    display: flex;
    
    flex-direction: row;
    
   
}


.gpt3__navbar-links_container p,
.gpt3__navbar-menu_container p{
    color: #152118;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 20px;
    height: 100%;
    
   
    text-decoration: none;
    text-align: center;
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    line-height: 40px;
    
    text-transform: capitalize;
    
    cursor: pointer;
    outline: none;
    border: 0;

}

.gpt3__navbar-links_container p:hover,
.gpt3__navbar-menu_container p:hover{
    background-color: #00B165;
    transition: all 0.4s linear;
}

.gpt3__navbar-menu{
    display: none;
}

.gpt3__navbar-menu svg{
    cursor: pointer;
}

.gpt3__navbar-menu_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    

    text-align: end;

    
        /* ff 3.6+ */
    background-color:-moz-linear-gradient(64deg, #00B165 0%, #006F61 99%); 

    /* safari 5.1+,chrome 10+ */
    background-color:-webkit-linear-gradient(64deg, #00B165 0%, #006F61 99%);

    /* opera 11.10+ */
    background-color:-o-linear-gradient(64deg, #00B165 0%, #006F61 99%);

    /* ie 6-9 */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006F61', endColorstr='#00B165', GradientType=0 );

    /* ie 10+ */
    background-color:-ms-linear-gradient(64deg, #00B165 0%, #006F61 99%);

    /* global 94%+ browsers support */
    background:linear-gradient(64deg, #00B165 0%, #006F61 99%);
      
    position: absolute;
    top: 83px;
    right: 0;
    margin-top: 0.78rem;
    min-width: 180px;
    min-height: 100%;
    align-items: center;
    
    /* border-radius: 5px; */
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}





@media screen and (max-width:1050px) {

    .gpt3__navbar-links_logo img {
        width: 52px;
        height: 42px;
    }

    .gpt3__navbar-links_container{
        display: none;
    }

    .gpt3__navbar-menu{
        display: flex;
       
    }
    .gpt3__navbar{
        padding: 0.8rem 1.7rem;
    }
    
}

@media screen and (max-width:550px) {
    .gpt3__navbar{
        padding: 0.8rem 1rem;
    }
    
}

@media(max-width:550){
    .gpt3__navbar-menu_container{
     /* ff 3.6+ */
     background-color:-moz-linear-gradient(124deg, #00B165F5 46%, #006F61 100%); 

     /* safari 5.1+,chrome 10+ */
     background-color:-webkit-linear-gradient(124deg, #00B165F5 46%, #006F61 100%);
 
     /* opera 11.10+ */
     background-color:-o-linear-gradient(124deg, #00B165F5 46%, #006F61 100%);
 
     /* ie 6-9 */
     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006F61', endColorstr='#00B165', GradientType=0 );
 
     /* ie 10+ */
     background-color:-ms-linear-gradient(124deg, #00B165F5 46%, #006F61 100%);
 
     /* global 94%+ browsers support */
     background:linear-gradient(124deg, #00B165F5 46%, #006F61 100%);
    }
}