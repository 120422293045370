*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body{
    background-image: url("./assets/bck.png");
    background-size: 1920px 1080px;
}

a{
    color: unset;
    text-decoration: none;
}
.back{
    background-color: black;
    position: absolute;
}
.gradient__bg         


{

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 3% 25%, #7FC795 1%, #00B165 35%, #006F61 99%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 3% 25%, #7FC795 1%, #00B165 35%, #006F61 99%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 3% 25%, #7FC795 1%, #00B165 35%, #006F61 99%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 3% 25%, #7FC795 1%, #00B165 35%, #006F61 99%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, #7FC795 1%, #00B165 35%, #006F61 99%);

}
            
        
.gradient__text{
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}            
            
.section__padding{
    padding: 4rem 6rem;
}

.section__margin{
    margin: 4rem 6rem;
}

.shadow-drop-2-center {
	-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
@keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  
  @media screen and (max-width:700px){
    .section__padding{
        padding: 4rem;
    }
    
    .section__margin{
        margin: 4rem ;
    }
  }
 
  @media screen and (max-width:550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    
    .section__margin{
        margin: 4rem 2rem;
    }
  }
 
       
         
